<app-layout-header *ngIf="!layoutService.isEmbed"></app-layout-header>
<app-loader></app-loader>
<div id="layout-content" [ngClass]="{ 'confirmation-screen': stepRouteService.isConfirmationScreen }">
  <div class="voucher-contents d-flex flex-column flex-lg-row">
    <div class="content-left">
      <div class="infos" *ngIf="!layoutService.isSiteReady">{{ 'SCREEN_GIFTVOUCHER_COMMON.maintenant_infos' | translate }}</div>
      <router-outlet *ngIf="layoutService.isSiteReady"></router-outlet>
      <div
        *ngIf="layoutService.isSiteReady && stepRouteService.isStepRouteScreen && !stepRouteService.isConfirmationScreen && !(loaderService.loaderSubject$ | async)"
        class="navigation-bar d-flex align-items-center"
        [ngClass]="{
          'justify-content-end': stepRouteService.isHomeScreen,
          'justify-content-center': stepRouteService.isPaymentScreen,
          'justify-content-between': !stepRouteService.isHomeScreen && !stepRouteService.isPaymentScreen,
          'navigation-bar-home': stepRouteService.isHomeScreen,
          'navigation-bar-contact': stepRouteService.isContactSettingScreen,
        }">
        <a *ngIf="!stepRouteService.isHomeScreen" href="#" [attr.disabled]="true" (click)="goToPreviousStep($event)">{{'SCREEN_GIFTVOUCHER_COMMON.back' | translate }}</a>
        <button
          *ngIf="!stepRouteService.isPaymentScreen"
          [ngClass]="{'sp-btn': theme.brand === 'sp'}"
          class="btn-gift-voucher-continue d-flex justify-content-center align-items-center border-0"
          (click)="goToNextStep()"
        >
          {{ 'SCREEN_GIFTVOUCHER_COMMON.continue' | translate }}
        </button>
      </div>
      <div *ngIf="stepRouteService.isContactSettingScreen" class="term-text-wrapper d-flex flex-column gap-1">
        <p class="term-text" [innerHTML]="('SCREEN_GIFTVOUCHER_COMMON.term_text_1_' + theme?.brand) | translate:{ link1: termLinks[0].href, link2: termLinks[1].href }"></p>
        <p class="term-text">
          {{ ('SCREEN_GIFTVOUCHER_COMMON.term_text_2_' + theme?.brand) | translate }}
          <span><a href="#" (click)="onClickHere($event)">{{ 'SCREEN_GIFTVOUCHER_COMMON.click_here' | translate }}</a>.</span>
        </p>
      </div>
      <div *ngIf="stepRouteService.isContactSettingScreen && showCommercialSetting">
        <p class="mb-2">{{ 'SCREEN_GIFTVOUCHER_COMMON.commercial_setting' | translate }}</p>
        <app-commerial-form></app-commerial-form>
        <p class="text-disabled mb-5">{{ 'SCREEN_GIFTVOUCHER_COMMON.commercial_info' | translate }}</p>
      </div>
    </div>
    <div *ngIf="stepRouteService.isStepRouteScreen && !stepRouteService.isConfirmationScreen" class="content-right d-none d-lg-block">
      <div class="voucher-card-wrapper position-relative">
        <img class="voucher-card w-100 h-auto" src="{{theme.card}}" alt="Voucher Card">
        <div class="voucher-amount position-absolute">
          <p class="d-flex m-0"><span *ngIf="voucherValue && locale === 'en'">€</span>{{ voucherValue ? voucherValue * (voucherQuantity ?? 1) : '' }}<span *ngIf="voucherValue && locale === 'fr'">€</span></p>
          <p class="voucher-amount-detail d-flex m-0" *ngIf="voucherQuantity && voucherValue"><span>{{ voucherQuantity }}</span>&nbsp;x <span *ngIf="locale === 'en'">€</span>{{ voucherValue }}<span *ngIf="locale === 'fr'">€</span></p>
        </div>
      </div>
      <div *ngIf="hasReceiver && !stepRouteService.isHomeScreen" class="voucher-receiver-information d-flex flex-column gap-2 p-3">
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.from' | translate }} : </p>
          <p class="value m-0">{{ buyerName || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.for' | translate }} : </p>
          <p class="value m-0" [title]="receiverName">{{ receiverName || "" }}</p>
        </div>
        <div class="info-wrapper d-flex flex-wrap gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.recipient_email' | translate }} :  </p>
          <p class="value m-0" [title]="receiverEmail">{{ receiverEmail || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.send_date' | translate }} : </p>
          <p class="value m-0">{{ receiveDate && hasReceiver ? (receiveDate | date:'dd/MM/yyyy') : "" }}</p>
        </div>
      </div>
      <div *ngIf="!hasReceiver && hasBuyerInfo && !stepRouteService.isHomeScreen" class="voucher-receiver-information d-flex flex-column gap-2 p-3">
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.firstname' | translate }} : </p>
          <p class="value m-0" [title]="buyerFirstname">{{ buyerFirstname || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.lastname' | translate }} : </p>
          <p class="value m-0" [title]="buyerLastname">{{ buyerLastname || "" }}</p>
        </div>
        <div class="info-wrapper d-flex gap-2">
          <p class="label m-0">{{ 'SCREEN_GIFTVOUCHER_COMMON.email' | translate }} : </p>
          <p class="value m-0" [title]="buyerEmail">{{ buyerEmail || "" }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-layout-footer *ngIf="!layoutService.isEmbed"></app-layout-footer>
<app-sticky-bar *ngIf=" layoutService.isSiteReady && !layoutService.isEmbed && !stepRouteService.isConfirmationScreen"></app-sticky-bar>

<app-layout-symbols></app-layout-symbols>
