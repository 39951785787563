import { Injectable } from '@angular/core';
import { THEMES } from './theme.const';
import { Theme } from './theme.model';
import { Link } from '@shared/models/link';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themeLoaded = false;
  theme: Theme | null = null;

  constructor(private userService: UserService) { }

  loadTheme(): void {
    const currentHost = window.location.hostname;
    const defaultTheme = THEMES.find((theme: any) => theme.host.includes('default'));
    const theme = THEMES.find((theme: any) => theme.host.includes(currentHost) || currentHost.includes(theme.partialHost));

    if (theme) {
      this.theme = {
        ...theme,
        host: currentHost,
      };
      if(this.theme.host === 'localhost') {
        this.theme.host = 'gift-onsite360-dev.groupepvcp.com';
      }
      this.setHeadData();
      this.setThemeStyle(this.theme);
      this.setPrivacyCenterScript(this.theme);

    } else {
      console.error(`Loading theme error : Unable to find style for host ${window.location.hostname}`);
      this.theme = {
        ...defaultTheme,
        host: currentHost,
      };
      this.setHeadData();
      this.setThemeStyle(this.theme);
      console.error(`Default theme has been loaded`);
      // throw `Loading theme error : Unable to find style for host ${window.location.hostname}`;
    }
  }

  setThemeStyle(theme: Theme): void {
    const themeCss = `${theme.name}.css`;
    const head = window.document.getElementsByTagName('head')[0];
    let clientTheme = window.document.getElementById('client-theme') as HTMLLinkElement;

    if (clientTheme) {
      clientTheme.href = themeCss;
    } else {
      const style = window.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = themeCss;
      style.onload = () => {
        this.themeLoaded = true;
      }
      head.appendChild(style);
    }
  }

  setPrivacyCenterScript(theme: Theme): void {
    const head = window.document.getElementsByTagName('head')[0];
    const script1 = window.document.createElement('script');
    const script2 = window.document.createElement('script');
    script1.type = "text/javascript";
    script1.textContent = theme.privacyCenterScript1;
    script2.type = "text/javascript";
    script2.textContent = theme.privacyCenterScript2;

    head.appendChild(script1);
    head.appendChild(script2);
  }

  setHeadData(): void {
    const appTitle: HTMLTitleElement = document.querySelector('#appTitle');
    const appFavicon: HTMLLinkElement = document.querySelector('#appFavicon');

    if(appTitle && this.theme.appTitle) {
      appTitle.text = this.theme.appTitle;
    }

    if(appFavicon && this.theme.appFavicon) {
      appFavicon.href = this.theme.appFavicon;
    }
  }

  get footerLinks(): Link[] {
    return this.theme.footerLinks[this.userService.marketLang] ?? this.theme.footerLinks["fr-fr"];
  }

  get paymentTermLinks(): Link[] {
    return this.theme.paymentTermLinks[this.userService.marketLang] ?? this.theme.paymentTermLinks["fr-fr"];
  }

  get phoneNumber(): string {
    return this.theme.phoneNumber[this.userService.marketLang] ?? `by_phone_${this.theme.brand}`;
  }

  get termLinks(): Link[] {
    return this.theme.termLinks[this.userService.marketLang] ?? this.theme.termLinks["fr-fr"];
  }
}
